import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import WalletImage from "./download-wallet-image"

import "./download-wallet.css"

const DownloadWallet = () => (
  <Fragment>
    <div className="container" style={{padding: '0 0 40px 0'}}>
      <h1 className="download-wallet-title">
        <span>
          <FormattedMessage id="org.massnet.components.download.wallet.title" />
        </span>
      </h1>
      <div className="download-wallet-content">
        <div className="download-wallet-description" style={{display:'flex'}}>
          <div className="text-center" style={{flex:'.5'}}>
            <a className="link" target="_blank" href="https://github.com/massnetorg/MassNet-wallet/releases/download/v2.0.3/masswallet-darwin-amd64.tgz">
              <FormattedMessage id="org.massnet.components.download.wallet.button1.title" />
            </a>
            <br />
            <a className="link" target="_blank" href="https://github.com/massnetorg/MassNet-wallet/releases/download/v2.0.3/masswallet-linux-amd64.tgz">
              <FormattedMessage id="org.massnet.components.download.wallet.button2.title" />
            </a>
            <br />
            <a className="link" href="https://github.com/massnetorg/MassNet-wallet/releases/download/v2.0.3/masswallet-windows-amd64.zip">
              <FormattedMessage id="org.massnet.components.download.wallet.button3.title" />
            </a>
          </div>
          <div className="text-center" style={{flex:'.5'}}>
           <a className="link" href="https://download.massnet.org/wallet/mass-wallet-full-node-lastest-mainnet-macos.dmg">
              <FormattedMessage id="org.massnet.components.download.wallet.button4.title" />
            </a>
            <br />
            <a className="link" href="https://download.massnet.org/wallet/mass-wallet-full-node-lastest-mainnet-windows.exe">
              <FormattedMessage id="org.massnet.components.download.wallet.button5.title" />
            </a>
          </div>
        </div>
        {/* <div className="download-image">
          <WalletImage />
        </div> */}
      </div>
    </div>
  </Fragment>
)

export default DownloadWallet
