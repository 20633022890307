import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import { StaticQuery, graphql } from "gatsby"

import "./download-code.css"

const DownloadCode = () => (
  <Fragment>
    <div className="container" style={{ padding: "1px 0 40px 0" }}>
      <h1 className="download-code-title">
        <span>
          <FormattedMessage id="org.massnet.components.download.code.title" />
        </span>
      </h1>

      <div className="download-code-content text-center">
        <a
          className="link"
          href="https://github.com/massnetorg/MassNet-miner"
        >
          <FormattedMessage id="org.massnet.components.download.code.miner" />
        </a>
        <br />
        <a
          className="link"
          href="https://github.com/massnetorg/MassNet-wallet"
        >
          <FormattedMessage id="org.massnet.components.download.code.wallet" />
        </a>

      </div>
      {/* <StaticQuery
        query={graphql`
          query {
            minerIcon: file(relativePath: { eq: "miner-code.svg" }) {
              publicURL
            }
            walletIcon: file(relativePath: { eq: "wallet-code.svg" }) {
              publicURL
            }
          }
        `}
        render={data => (
          <div className="download-code-content">
            <div className="code-card">
              <img
                alt="MASS,区块链共识引擎,POC,存储空间租赁,容量证明,共识引擎,挖矿"
                src={data.minerIcon.publicURL}
              />
              <h5>
                <FormattedMessage id="org.massnet.components.download.code.miner" />
              </h5>
              <a
                className="download-link"
                href="https://download.massnet.org/opensource/mass-miner-opensource-latest.zip"
                download=""
              >
                <FormattedMessage id="org.massnet.components.download.code.download" />
              </a>
            </div>
            <div className="code-card">
              <img
                alt="MASS,区块链共识引擎,POC,存储空间租赁,容量证明,共识引擎,挖矿"
                src={data.walletIcon.publicURL}
              />
              <h5>
                <FormattedMessage id="org.massnet.components.download.code.wallet" />
              </h5>
              <a
                className="download-link"
                href="https://download.massnet.org/opensource/mass-wallet-opensource-latest.zip"
                download=""
              >
                <FormattedMessage id="org.massnet.components.download.code.download" />
              </a>
            </div>
          </div>
        )}
      /> */}
    </div>
  </Fragment>
)

export default DownloadCode
