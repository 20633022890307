import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"

import "./download-mirror.css"

const DownloadMirror = () => (
  <Fragment>
    <div className="container" style={{marginBottom: '60px'}}>
      <h1 className="download-miner-title">
        <span>
          <FormattedMessage id="org.massnet.components.download.mirror.title" />
        </span>
      </h1>
      <div className="download-miner-content">
        <div className="download-miner-description">
          <div className="download-miner-buttons text-center">
            <a className="link" target="_blank" href='https://download.massnet.org/snapshot/chain.zip' download>
                <span><FormattedMessage id="org.massnet.components.download.mirror.button" /></span><br />
            </a>
          </div>
          <div className="download-miner-buttons text-center">
            <a className="link" target="_blank" href='https://download.massnet.org/snapshot/windows-chain.zip' download>
                <span><FormattedMessage id="org.massnet.components.download.mirror.button2" /></span><br />
            </a>
          </div>
          <div className="download-miner-buttons text-center">
            <a className="link" target="_blank" href='https://download.massnet.org/snapshot/mac-chain.zip' download>
                <span><FormattedMessage id="org.massnet.components.download.mirror.button3" /></span><br />
            </a>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
)

export default DownloadMirror
