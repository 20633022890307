import React from "react"
import { FormattedMessage } from "react-intl"
import ReactNavbar from "./navbar"

import {
  Jumbotron,
  Container,
} from "reactstrap"

import "./header-download.css"

const HeaderDownload = () => (
  <div>
    <ReactNavbar />
    <Jumbotron fluid id="header-download-bg">
      <Container fluid>
        {/* <h1 className="mass-intro">
                <FormattedMessage id="org.massnet.components.header.mass" />
              </h1> */}
        <p className="mass-desc">
          <FormattedMessage id="org.massnet.components.header.download_desc.title" />
        </p>
      </Container>
    </Jumbotron>
  </div>
)

export default HeaderDownload
