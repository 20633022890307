import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderDownload from "../components/header-download"
import DownloadClient from "../components/download-client"
import DownloadWallet from "../components/download-wallet"
import DownloadMiner from "../components/download-miner"
import DownloadCode from "../components/download-code"

import DownloadMirror from "../components/download-mirror"
const DownloadPage = ({ pageContext: { locale, keywords } }) => (
  <Layout locale={locale}>
    <HeaderDownload />
    <SEO
      title="Download"
      keywords={[
        ...keywords,
        `MASS Blockchain Consensus Engine`,
        `MASS Blockchain Consensus Engine Download`,
        `MASS 全节点钱包下载`,
      ]}
      lang={locale}
    />
    <DownloadWallet />
    <div style={{background: '#F8F8F8'}}>
      <DownloadMiner />
    </div>
    <DownloadMirror />
    <div style={{background: '#F8F8F8'}}>
      <DownloadCode />
    </div>
    {/* <DownloadClient /> */}
  </Layout>
)

DownloadPage.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    keywords: PropTypes.array.isRequired,
  }).isRequired,
}

export default DownloadPage
