import React, { Fragment } from "react"
import { FormattedMessage } from "react-intl"
import MinerImage from "./download-miner-image"

import "./download-miner.css"

const DownloadClient = () => (
  <Fragment>
    <div className="container" style={{ padding: "1px 0 40px 0" }}>
      <h1 className="download-miner-title">
        <span>
          <FormattedMessage id="org.massnet.components.download.miner.title" />
        </span>
      </h1>
      <div className="download-miner-content ">
        <div className="download-miner-description">
          {/* <p><FormattedMessage id="org.massnet.components.download.miner.content" /></p> */}
          <div className="download-miner-buttons text-center">
            <a
              className="link"
              href="https://github.com/massnetorg/MassNet-miner/releases/download/v2.0.3/massminer-darwin-amd64.tgz"
            >
              {/* <button className="download-miner-mac"> */}
              <span>
                <FormattedMessage id="org.massnet.components.download.miner.button1.title" />
              </span>
              <br />
              {/* <span className="download-miner-mac-version"><FormattedMessage id="org.massnet.components.download.miner.button1.version" /></span> */}
              {/* </button> */}
            </a>

            {/* <br /> */}
            {/* <a href='https://download.massnet.org/wallet/mass-release-testnet-v1.tar.gz'>
              <button className="download-miner-linux">
                <span><FormattedMessage id="org.massnet.components.download.miner.button2.title" /></span><br />
                <span className="download-miner-linux-version"><FormattedMessage id="org.massnet.components.download.miner.button2.version" /></span>
              </button>
            </a> */}
            <a
              className="link"
              href="https://github.com/massnetorg/MassNet-miner/releases/download/v2.0.3/massminer-linux-amd64.tgz"
            >
              {/* <button className="download-miner-windows"> */}
              <span>
                <FormattedMessage id="org.massnet.components.download.miner.button2.title" />
              </span>
              <br />
              {/* <span className="download-miner-windows-version"><FormattedMessage id="org.massnet.components.download.miner.button3.version" /></span> */}
              {/* </button> */}
            </a>
            <a
              className="link"
              href="https://github.com/massnetorg/MassNet-miner/releases/download/v2.0.3/massminer-windows-amd64.zip"
            >
              {/* <button className="download-miner-windows"> */}
              <span>
                <FormattedMessage id="org.massnet.components.download.miner.button3.title" />
              </span>
              <br />
              {/* <span className="download-miner-windows-version"><FormattedMessage id="org.massnet.components.download.miner.button3.version" /></span> */}
              {/* </button> */}
            </a>
          </div>
        </div>
        {/* <div className="download-image">
          <MinerImage />
        </div> */}
      </div>
    </div>
  </Fragment>
)

export default DownloadClient
